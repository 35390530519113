<template>
  <a-row :gutter="24" type="flex">
    <a-col :span="24" class="mb-24">
      <a-card
        :bordered="false"
        class="header-solid h-full"
        :bodyStyle="{ paddingTop: 0 }"
      >
        <template #title>
          <h5 class="font-semibold text-center">{{ flow.name }} Update</h5>
        </template>
        <a-form
          id="components-form-demo-normal-login"
          :form="form"
          class="flow-update-form"
          @submit="handleSubmit"
        >
          <a-row :gutter="24" type="flex">
            <a-col :span="24" :md="24" class="mb-24">
              <a-form-item class="mb-10">
                <a-input
                  v-decorator="[
                    'title',
                    {
                      initialValue: flow.title,
                      rules: [
                        { required: true, message: 'Please input title!' },
                      ],
                    },
                  ]"
                  placeholder="Title"
                >
                </a-input>
              </a-form-item>
              <a-form-item class="mb-10" label="Flow Range">
                <a-range-picker
                  v-decorator="[
                    'range-picker',
                    {
                      initialValue: [flow.start_date, flow.end_date],
                      rules: [
                        {
                          type: 'array',
                          required: true,
                          message: 'Please select start and end date!',
                        },
                      ],
                    },
                  ]"
                  :disabled-date="disabledDate"
                  @change="handleDateChange"
                />
              </a-form-item>
              <a-form-item class="mb-10">
                <div
                  style="
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    align-items: center;
                    gap: 5px;
                  "
                >
                  <div>
                    <label>Status: </label>
                    <a-switch
                      v-model:checked="status"
                      style="margin-left: 6px"
                    />
                  </div>
                </div>
              </a-form-item>

              <a-form-item
                v-for="(flow, flowIndex) in flowLists"
                :key="flowIndex"
              >
                <p>Feature for {{ moment(flow.date).format("DD-MM-YYYY") }}</p>
                <a-row
                  v-for="(item, itemIndex) in flow.items"
                  type="flex"
                  justify="space-between"
                  :gutter="16"
                >
                  <a-col :span="16">
                    <a-select
                      placeholder="Select Feature"
                      show-search
                      :filter-option="filterOption"
                      :value="item.feature_id"
                      @change="
                        handleFeatureChange(flowIndex, itemIndex, $event)
                      "
                    >
                      <a-select-option
                        v-for="(value, id) in featureList"
                        :key="value.id"
                      >
                        {{ value.name }} ({{ value.parent }})
                      </a-select-option>
                    </a-select>
                  </a-col>
                  <a-col :span="4">
                    <a-time-picker
                      :value="
                        item.time.length > 0 ? moment(item.time, 'HH:mm') : null
                      "
                      format="HH:mm"
                      @change="
                        handleFeatureTimeChange($event, flowIndex, itemIndex)
                      "
                    />
                  </a-col>
                  <a-col :span="4">
                    <a-row
                      type="flex"
                      justify="start"
                      align="middle"
                      style="height: 100%"
                    >
                      <a-col>
                        <a-button
                          type="danger"
                          @click="removeFeature(flowIndex, itemIndex)"
                          >Remove</a-button
                        >
                      </a-col>
                    </a-row>
                  </a-col>
                </a-row>
                <a-row type="flex" justify="start">
                  <a-col :span="2">
                    <a-button
                      type="primary"
                      @click="addFeature(flowIndex, flow.date)"
                      >Add Feature</a-button
                    >
                  </a-col>
                </a-row>
              </a-form-item>
            </a-col>
          </a-row>

          <a-form-item>
            <a-button
              type="primary"
              block
              html-type="submit"
              class="login-form-button"
            >
              <a-spin v-if="confirmLoading" />
              Update
            </a-button>
          </a-form-item>
        </a-form>
      </a-card>
    </a-col>
  </a-row>
</template>

<script>
import { sendRequest } from "@/http/axios.method";
import event from "ant-design-vue/lib/_util/css-animation/Event";
import moment from "moment";
export default {
  name: "FeatureUpdate",
  computed: {
    event() {
      return event;
    },
  },
  data() {
    return {
      config: {
        Authorization: "Bearer " + this.$store.state.authUser.token,
      },
      flowId: this.$route.query.id,
      confirmLoading: false,
      flow: [],
      status: false,
      confirmLoading: false,
      dateRange: [],
      featureList: [],
      flowLists: [],
      flowListBK: [],
      removeItems: [],
    };
  },
  beforeCreate() {
    // Creates the form and adds to it component's "form" property.
    this.form = this.$form.createForm(this, { name: "flow_update" });
  },
  created() {
    this.getFeatureList();
    this.getFlowDetails();
  },
  methods: {
    moment,
    disabledDate(current) {
      // Can not select days before today and today
      return current && current < moment().endOf("day");
    },
    addFeature(flowIndex, flowDate) {
      this.flowLists[flowIndex]["items"].push({
        feature_id: "",
        time: "",
        date: flowDate,
        id: "",
      });
    },
    handleFeatureTimeChange(time, flowIndex, itemIndex) {
      this.flowLists[flowIndex]["items"][itemIndex].time = time.format("HH:mm");
    },
    handleFeatureChange(flowIndex, itemIndex, value) {
      this.flowLists[flowIndex]["items"][itemIndex].feature_id = value;
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    removeFeature(flowIndex, itemIndex) {
      if (this.flowLists[flowIndex]["items"][itemIndex].id) {
        this.removeItems.push(this.flowLists[flowIndex]["items"][itemIndex].id);
      }
      this.flowLists[flowIndex]["items"].splice(itemIndex, 1);
    },
    getFlowDetails() {
      sendRequest("get", "admin/flow/" + this.flowId, {}, this.config)
        .then((response) => {
          if (response.success) {
            this.flow = response.data;
            this.status = this.flow.status === 1;
            this.initiateFeatureData(response.data.featureFlow);
          } else {
            if (response.status_code === 401) {
              this.$store.dispatch("logout");
              this.$router.push("/login");
            }
          }
        })
        .catch(() => {})
        .finally(() => {});
    },

    initiateFeatureData(feature_flows) {
      const flowLists = [];
      feature_flows.forEach((item) => {
        const data = {
          id: item.id,
          feature_id: item.feature_id,
          time: item.scheduled_time,
          date: item.scheduled_date,
          flow_id: item.flow_id,
        };

        const foundIndex = flowLists.findIndex(
          (flow) => flow.date === item.scheduled_date
        );

        if (foundIndex == -1) {
          //doesn't exist
          flowLists.push({ date: item.scheduled_date, items: [data] });
        } else {
          //date already exist
          flowLists[foundIndex]["items"].push(data);
        }
      });

      this.flowLists = flowLists;
      this.flowListBK = flowLists;
    },
    async handleSubmit(e) {
      e.preventDefault();

      this.form.validateFields((err, values) => {
        if (!err) {
          this.confirmLoading = true;
          let form_data = new FormData();
          let configHeader = {
            "content-type": "multipart/form-data",
            Authorization: "Bearer " + this.$store.state.authUser.token,
          };
          const dateRange = values["range-picker"];
          const startDate = dateRange[0];
          const endDate = dateRange[1];
          let payload = {
            title: values.title,
            start_date: startDate,
            end_date: endDate,
            status: this.status ? 1 : 0,
          };
          for (let key in payload) {
            form_data.append(key, payload[key]);
          }
          this.flowLists.forEach((flows, flowIndex) => {
            flows.items.forEach((flowItem, flowItemIndex) => {
              form_data.append(
                `flows[${flowIndex}][${flowItemIndex}][time]`,
                flowItem.time
              );
              form_data.append(
                `flows[${flowIndex}][${flowItemIndex}][id]`,
                flowItem.id ? flowItem.id : ""
              );
              form_data.append(
                `flows[${flowIndex}][${flowItemIndex}][feature_id]`,
                flowItem.feature_id
              );
              form_data.append(
                `flows[${flowIndex}][${flowItemIndex}][date]`,
                flowItem.date
              );
            });
          });
          if (this.removeItems.length > 0) {
            //remove items
            this.removeItems.forEach((item, index) => {
              form_data.append(`remove_items[${index}]`, item);
            });
          }

          sendRequest(
            "post",
            "admin/flow/" + this.flowId,
            form_data,
            configHeader
          )
            .then((response) => {
              if (response.success) {
                this.confirmLoading = false;
                this.$store.commit("setMessage", response.message);
                this.$router.push("/flows");
              } else {
                if (response.status_code === 401) {
                  this.$store.dispatch("logout");
                  this.$router.push("/login");
                }
              }
            })
            .catch(() => {
              this.confirmLoading = false;
            })
            .finally(() => {
              this.confirmLoading = false;
            });
        }
      });
    },
    handleDateChange(dates) {
      const [start, end] = dates;
      const timeDifference =
        new Date(end.format("YYYY-MM-DD")).getTime() -
        new Date(start.format("YYYY-MM-DD")).getTime();
      let dayDiff = Math.floor(timeDifference / (1000 * 60 * 60 * 24)) + 1;
      var startDate = start.clone();
      var removeItems = [];
      if (dayDiff < this.flowLists.length) {
        //remove item value assign
        removeItems = this.flowListBK.slice(dayDiff, this.flowLists.length);
        this.flowLists = this.flowListBK.slice(0, dayDiff);
      } else {
        this.flowLists = this.flowListBK;
      }
      for (let i = 0; i < dayDiff; i++) {
        var dateFormat = startDate.format("YYYY-MM-DD");
        if (!this.flowLists[i]) {
          this.flowLists.push({
            date: dateFormat,
            items: [
              {
                feature_id: "",
                time: "",
                date: dateFormat,
                flow_id: "",
                id: "",
              },
            ],
          });
        } else {
          this.flowLists[i].date = dateFormat;
          this.flowLists[i].items.forEach((item, itemIndex) => {
            this.flowLists[i].items[itemIndex].date = dateFormat;
          });
        }
        startDate = startDate.add(1, "day");
      }

      const newRemove = [];
      removeItems.forEach((items) => {
        items.items.forEach((flow) => {
          newRemove.push(flow.id);
        });
      });
      this.removeItems = newRemove;
    },

    getFeatureList() {
      sendRequest("get", "admin/child_features", {}, this.config)
        .then((response) => {
          if (response.success) {
            let data = response.data;
            this.featureList = data.map(function (content, label) {
              return {
                id: content.id,
                name: content.name,
                parent: content.parent.name,
              };
            });
          } else {
            if (response.status_code === 401) {
              this.$store.dispatch("logout");
              this.$router.push("/login");
            }
          }
        })
        .catch(() => {})
        .finally(() => {});
    },
  },
};
</script>
